<template>
    <div class="my-address">
        <div class="my-head">
            <h3 class="my-title">地址管理</h3>
        </div>
        <div class="my-body">
            <div class="address-btn-wrapper">
                <a href="javascript:;" class="btn-primary btn-plain address-add" @click="showDialog(null)">新增地址</a>
                <small class="address-add-tips">最多可以创建20个收货地址</small>
            </div>
            <div v-for="item in addressList" :key="item.user_address_id" class="address-item">
                <h3 class="address-item-block address-name">
                    <span class="address-name-txt">{{ item.name }}</span>
                    <span v-if="item.isdefault==='1'" class="btn-primary btn-mini btn-yellow">默认</span>
                    <a v-else class="btn-primary btn-mini btn-dark" href="javascript:;" @click="setdefault(item.user_address_id)">设为默认</a>
                </h3>
                <p class="address-item-block">{{ item.mobile }}</p>
                <p class="address-item-block ">{{ item.province + item.city + (item.area || '') + item.address }}</p>
                <section class="address-toolbar">
                    <a class="address-link" href="javascript:;" @click="deladdress(item.user_address_id)">删除</a>
                    <a href="javascript:;" class="btn-primary btn-small btn-rounder" @click="showDialog(item)">编辑</a>
                </section>
            </div>
        </div>
        <el-dialog v-model="current" :visible.sync="dialogVisible" :province-list="provinceList" :type="type" @getData="getData" />
    </div>
</template>
<script>
import { getAddressList, setdefault, deladdress } from '@api/my'
import elDialog from './dialog'
export default {
    name: 'MyOrder',
    components: {
        elDialog
    },
    data() {
        return {
            activeName: '1',
            addressList: [
                // { addressid: '1', name: '大胖鸡', tel: '159****8085', address: '上海普陀区城区宁夏路627号', default: true },
                // { addressid: '2', name: '大胖鸡', tel: '159****8085', address: '上海普陀区城区宁夏路627号', default: false }
            ],
            type: 'add',
            current: null,
            dialogVisible: false,
            provinceList: []
        }
    },
    created() {
        this.getData()
        // this.getProvince()
    },
    methods: {
        async getData() {
            const res = await getAddressList()
            this.addressList = res.detail
            // 如果地址列表只有一条数据且没有设为默认，则自动设置为默认地址
            const addressList = this.addressList
            console.log('addressList.length', addressList.length)
            if (addressList.length > 0) {
                const defaultAddress = addressList.find(item => item.isdefault === '1')
                if (addressList.length === 1 && !defaultAddress) {
                    this.setdefault(this.addressList[0].user_address_id)
                }
            }
        },
        // async getProvince() {
        //     const res = await getProvince()
        //     this.provinceList = res.detail
        // },
        showDialog(item) {
            console.log('item', item)
            this.current = item
            this.type = item ? 'edit' : 'add'
            this.dialogVisible = true
        },
        // 设置默认收货地址
        async setdefault(id) {
            const res = await setdefault({ user_address_id: id })
            this.getData()
            this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
        },
        // 删除收货地址
        async deladdress(id) {
            this.$confirm('确定删除这个地址吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async() => {
                const res = await deladdress({ user_address_id: id })
                this.getData()
                this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            }).catch(() => {})
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    min-height: 1000px;
    position: relative;
    padding: 20px;
    background-color: $white;
}
.address-btn-wrapper {
    margin-bottom: 20px;
}
.address-add-tips {
    padding: 0 10px;
    vertical-align: bottom;
    color: $light;
}
.address-item {
    position: relative;
    margin-bottom: 10px;
    padding: 15px 20px;
    border: 1px solid $lighter;
}
.address-item-block {
    margin-bottom: 10px;
    word-break: break-all;
    color: $light;
}
.address-name {
    font-weight: normal;
    color: $black3;
    .btn-yellow {
        margin-left: 10px;
        &:hover,
        &.active {
            border-color: $yellow;
            background-color: $yellow;
        }
    }
    .btn-dark {
        @extend .btn-yellow;
        border-color: $light;
        background-color: $light;
    }
}
.address-name-txt {
    vertical-align: middle;
}
.address-toolbar {
    position: absolute;
    top: 15px;
    right: 20px;
    text-align: right;
}
.address-link {
    @include inlineBlock();
    padding: 5px 10px;
    color: $light;
}
</style>

<template>
    <div>
        <el-dialog class="dialog-my" :before-close="closeDialog" :title="type==='add'?'新增地址':'编辑地址'" :close-on-click-modal="false" :visible.sync="visible" width="460px">
            <el-form ref="ruleForm" :model="ruleForm" class="simple-form">
                <el-form-item prop="name" label="收货人姓名" :rules="[{ required: true, message: '请输入收货人姓名', trigger: 'blur' }]">
                    <el-input v-model.trim="ruleForm.name" :maxlength="16" clearable @keyup.native="ruleForm.name=ruleForm.name.replace(/\s+/g, '')" @keyup.enter.native="onSubmit" />
                </el-form-item>
                <el-form-item prop="mobile" label="手机号" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern: reg.tel, message: reg.telPrompt, trigger: 'blur' }]">
                    <el-input v-model.trim="ruleForm.mobile" :maxlength="11" clearable @keyup.native="ruleForm.mobile=ruleForm.mobile.replace(/[^\d]/g, '')" @keyup.enter.native="onSubmit" />
                </el-form-item>
                <el-form-item prop="areacode" label="所在区域" :rules="[{ required: true, message: '请选择所在区域', trigger: 'change' }]">
                    <!-- <el-cascader v-if="showCascader" v-model="areaArr" :props="{ lazy: true, lazyLoad: lazyLoad }" clearable @change="onChange" /> -->
                    <!-- <el-cascader :props="props"></el-cascader> -->
                    <province-city-area ref="provinceCityArea" :provincecode-default="ruleForm.provincecode" :citycode-default="ruleForm.citycode" :areacode-default="ruleForm.areacode" @setFullAddress="getFullAddress" />
                </el-form-item>
                <el-form-item prop="address" label="详细地址" :rules="[{ required: true, message: '请输入详细地址', trigger: 'blur' }]">
                    <el-input v-model="ruleForm.address" type="textarea" size="medium" placeholder="请输入详细地址" maxlength="200" show-word-limit clearable @keyup.enter.native="onSubmit" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <a href="javascript:;" class="btn-primary btn-round" @click="onSubmit">保存</a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { addaddress, editaddress } from '@api/my'
import provinceCityArea from '@c/provinceCityArea'
import mixin from '@c/mixins'
export default {
    name: 'AddressDialog',
    mixins: [mixin],
    props: ['visible', 'type', 'value'],
    components: {
        provinceCityArea
    },
    data() {
        let id = 0;
        return {
            ruleForm: {
                name: '',
                mobile: '',
                address: '',
                provincecode: '',
                citycode: '',
                areacode: ''
            },
            areaArr: [],
            showCascader: false,
            resolve: null,
            // props: {
            //     lazy: true,
            //     lazyLoad (node, resolve) {
            //         const { level } = node;
            //         setTimeout(() => {
            //         const nodes = Array.from({ length: level + 1 })
            //             .map(item => ({
            //             value: ++id,
            //             label: `选项${id}`,
            //             leaf: level >= 2
            //             }));
            //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            //         resolve(nodes);
            //         }, 1000);
            //     }
            // }
        }
    },
    watch: {
        visible(val) {
            console.log('value, ruleForm', this.value, this.ruleForm)
            if (val) {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                this.ruleForm.provincecode = ''
                if (this.type === 'add') {
                    this.$refs.provinceCityArea && this.$refs.provinceCityArea.setProvince()
                    this.resetForm()
                } else {
                    for (const key in this.ruleForm) {
                        this.ruleForm[key] = this.value[key]
                    }
                    // this.areaArr = [this.ruleForm.provincecode, this.ruleForm.citycode, this.ruleForm.areacode]
                    // const currentProvinceObj = this.provinceList.find(item => item.provincecode === this.provinceList) || this.provinceList[0]
                    // const provinceNode = {
                    //     value: currentProvinceObj.code,
                    //     label: currentProvinceObj.name,
                    //     leaf: false
                    // }
                    // this.lazyLoad(provinceNode, this.resolve)
                    this.$set(this.ruleForm, 'user_address_id', this.value.user_address_id)
                }
                // this.showCascader = true // 切换地址选择级联重新渲染
            }
            // else {
            //     this.showCascader = false
            // }
            // console.log('value, ruleForm', this.value, this.ruleForm)
            // console.log('areaArr', this.areaArr)
        }
    },
    methods: {
        // async lazyLoad(node, resolve) {
        //     console.log('lazyLoad node', node)
        //     this.resolve = resolve
        //     const { level, value } = node
        //     const getMapData = (list) => {
        //         return list.map(item => ({
        //             value: item.code,
        //             label: item.name,
        //             leaf: level >= 2
        //         }))
        //     }
        //     // 省节点
        //     if (level === 0) {
        //         const nodes = getMapData(this.provinceList)
        //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        //         console.log('level === 0 nodes', nodes)
        //         resolve(nodes)
        //         const currentProvinceObj = this.provinceList.find(item => item.provincecode === this.ruleForm.provincecode) || this.provinceList[0]
        //         const provinceNode = {
        //             value: currentProvinceObj.code,
        //             label: currentProvinceObj.name,
        //             level: 1,
        //             leaf: false
        //         }
        //         // this.lazyLoad(provinceNode, this.resolve)
        //     } else if (level === 1) {
        //         const res = await getCity({ provincecode: value })
        //         const nodes = getMapData(res.detail)
        //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        //         resolve(nodes)
        //     } else if (level === 2) {
        //         const res = await getArea({ citycode: value })
        //         const nodes = getMapData(res.detail)
        //         // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        //         resolve(nodes)
        //     }
        // },
        closeDialog() {
            this.$emit('update:visible', false)
            this.resetForm()
        },
        resetForm() {
            for (const key in this.ruleForm) {
                this.ruleForm[key] = ''
            }
            // this.areaArr = ['', '', '']
            delete this.ruleForm.user_address_id
        },
        getFullAddress(addressObj) {
            console.log('full address', addressObj)
            this.ruleForm.provincecode = addressObj.province.code
            this.ruleForm.citycode = addressObj.city.code
            this.ruleForm.areacode = addressObj.area.code
            // this.ruleForm.address = addressObj.province.name + addressObj.city.name + addressObj.area.name
        },
        // onChange(val) {
        //     console.log(val)
        //     this.ruleForm.provincecode = val[0]
        //     this.ruleForm.citycode = val[1]
        //     this.ruleForm.areacode = val[2]
        // },
        async onSubmit() {
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = this.type === 'add' ? await addaddress(this.ruleForm) : await editaddress(this.ruleForm)
                    this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                    this.$emit('getData')
                    this.$emit('update:visible', false)
                    this.showCascader = false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
